<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header">
          <h3 class="page-title"> Roles & Permisos </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Roles</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los roles</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="TOGGLE_MODAL_CREATE()">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <role-create></role-create>
        <role-update :payload="roleSelected"></role-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import RoleCreate from '@/components/CreateRole'
import RoleUpdate from '@/components/EditRole'
import { mapMutations } from 'vuex'

export default {
  name: 'Role',
  data () {
    return {
      apiEndPoint: 'api/roles',
      fields: [
        {
          name: 'name',
          title: 'Nombre',
          formatter: (value) => {
            return value.charAt(0).toUpperCase() + value.slice(1)
          }
        }
      ],
      roleSelected: {}
    }
  },
  methods: {
    ...mapMutations('Role', ['TOGGLE_MODAL_CREATE', 'TOGGLE_MODAL_UPDATE']),
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', eventData => {
      this.roleSelected = eventData
      this.openModelUpdate()
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    RoleCreate,
    RoleUpdate
  }
}
</script>
