<template>
  <b-modal v-model="modalCreate" no-fade ref="create-role" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Rol</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group">
                <label for="exampleInputUsername1">Nombre</label>
                <input v-model="form.name" type="text" class="form-control" id="exampleInputUsername1" placeholder="Nombre">
                <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.name[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="createResource" class="btn btn-gradient-info btn-md">Guardar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CreateRole',
  data () {
    return {
      form: {
        name: ''
      },
      errors: {}
    }
  },
  methods: {
    ...mapActions('Role', ['create']),
    ...mapMutations('Role', ['TOGGLE_MODAL_CREATE']),
    createResource () {
      this.create(this.form)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.form = {
        name: ''
      }
      this.errors = {}
      this.TOGGLE_MODAL_CREATE(false)
      this.$events.fire('refresh-table')
      // this.$swal('Operación completada', '', 'success')
    }
  },
  computed: {
    ...mapGetters('Role', ['modalCreate'])
  }
}
</script>
