<template>
  <b-modal v-model="modalOpen" size="lg" no-fade ref="update-role" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Rol</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group mb-3">
                <label for="exampleInputUsername1">Nombre</label>
                <input v-model="payload.name" type="text" class="form-control" id="exampleInputUsername1" placeholder="Nombre">
                <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.name[0]}}</small>
                </div>
              </div>
              <div class="form-group mb-3 text-right">
                <button class="btn btn-outline-info btn-sm" type="button" @click.prevent="updateRole">Actualizar</button>
              </div>
              <div class="form-row" v-if="payload.permissions">
                <div class="form-group col-12 mb-2">
                  <label>Permisos</label>
                </div>
                <div class="col-12" v-bind:key="index" :class="((index+1) % 7) ? ' col-md-6 mb-2' : ' col-md-12 mb-4'" v-for="(permission, index) in permissionsArray">
                  <input class="d-inline-block" type="checkbox" @change="updateRolePermission(permission)" v-bind:id="permission.name" :checked="payload.permissions.filter(function(obj) { return obj.name === permission.name; }).length > 0 ? 'checked' : ''">
                  <label class="form-check-label ml-1 d-inline-block" :for="permission.alias">
                    {{ permission.alias }}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'updateRole',
  data () {
    return {
      role: [],
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      update: 'Role/update',
      getAll: 'Permission/getAll',
      updatePermission: 'Role/updatePermission'
    }),
    ...mapMutations('Role', ['TOGGLE_MODAL_UPDATE']),
    updateRolePermission (payload) {
      this.updatePermission({
        role: this.payload.id,
        permission: payload.id
      })
        .then(() => {
          // this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    updateRole () {
      this.update(this.payload)
        .then(() => {
          // this.reset()
          this.$swal('Operación Completada', '', 'success')
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.role = []
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
    }
  },
  mounted () {
    this.getAll()
  },
  computed: {
    ...mapGetters({
      modalUpdate: 'Role/modalUpdate',
      permissions: 'Permission/permissions'
    }),
    modalOpen () {
      return this.modalUpdate
    },
    permissionsArray () {
      return this.permissions
    }
  }
}
</script>
